import { useState } from 'react';
import { SupplyLineIcon } from '@floriday/floriday-icons';
import { AppBar as FloridayAppBar, Sidebar, SidebarItem } from '@floriday/floriday-ui';
import { grey } from '@floriday/floriday-ui/es/Styles/colors';
import { useTranslation } from 'react-i18next';

import { namespaces } from '@root/i18n';
import ClockSupplyLogoIcon from '@resources/Icons/ClockSupplyLogoIcon';
import ClockSupplyLogoIconWithoutText from '@resources/Icons/ClockSupplyLogoIconWithoutText';
import InspectScanIcon from '@resources/Icons/InspectScanIcon';
import { useBreakpointContext } from '@store/Providers/BreakpointProvider';
import { useFeatureFlags } from '@store/Providers/FeatureFlagsProvider';
import { usePresetContext } from '@store/Providers/PresetsProvider';
import { useStyles } from './AppBarStyles';
import UserMenuContainer from '../UserMenu/UserMenuContainer';

export default function AppBar() {
    const { selectedPreset } = usePresetContext();
    const { t } = useTranslation(namespaces.general);
    const [menuOpen, setMenuOpen] = useState(false);
    const { hasScanVisible } = useFeatureFlags();
    const { isBreakpointSmall } = useBreakpointContext();
    const { classes } = useStyles();
    const sidebarItems = [
        {
            title: t('routes.marketplace'),
            route: '/supply-overview',
            icon: (
                <SupplyLineIcon
                    className={location.pathname.includes('/supply-overview') ? classes.selected : classes.icon}
                />
            ),
        },
    ];

    if (!(selectedPreset?.isReadOnly ?? true) && hasScanVisible && isBreakpointSmall) {
        sidebarItems.push({
            title: t('routes.scan'),
            route: '/scan',
            icon: <InspectScanIcon className={location.pathname.includes('/scan') ? classes.selected : classes.icon} />,
        });
    }

    return (
        <>
            <FloridayAppBar
                menuButtonVisibility='always'
                menuButtonActive={menuOpen}
                onMenuButtonClick={() => setMenuOpen(!menuOpen)}
                logo={
                    isBreakpointSmall ? (
                        <ClockSupplyLogoIconWithoutText data-testid='appTitle' />
                    ) : (
                        <ClockSupplyLogoIcon data-testid='appTitle' />
                    )
                }
                rightContent={<UserMenuContainer />}
                classes={{ appbar: classes.header, logo: classes.logo }}
            />
            <Sidebar open={menuOpen} variant='temporary' onClose={() => setMenuOpen(false)}>
                {sidebarItems.map(sidebarItem => (
                    <SidebarItem
                        key={sidebarItem.title}
                        title={sidebarItem.title}
                        color={grey[800] ?? ''}
                        icon={sidebarItem.icon}
                        isActive={location.pathname.includes(sidebarItem.route)}
                        onCloseSidebar={() => setMenuOpen(false)}
                        href={sidebarItem.route}
                    />
                ))}
            </Sidebar>
        </>
    );
}
