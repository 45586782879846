import { useEffect, useMemo, useRef, useState } from 'react';
import { ArrowDownIcon, CloseIcon } from '@floriday/floriday-icons';
import { ToggleIconButton } from '@floriday/floriday-ui';
import {
    Box,
    ButtonGroup,
    ClickAwayListener,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Popper,
} from '@mui/material';
import { DigitalAuctionSupplyType } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { IUser } from '@features/Auth/Contracts/IUser';
import { getUserAccount } from '@features/Auth/auth';
import MarkButtonContainer from '@features/Supply/Components/Marking/MarkButton/MarkButtonContainer';
import { MarkSize } from '@features/Supply/Components/Marking/MarkingContainer/MarkingContainer';
import { namespaces } from '@root/i18n';
import { useActivityTracker } from '@store/Providers/ActivityTrackerProvider';
import { useBreakpointContext } from '@store/Providers/BreakpointProvider';
import { useStyles } from './MarkButtonMenuStyles';
import AccountsMarkingList from '../AccountsMarkingList/AccountsMarkingList';

interface MarkButtonMenuProps {
    readonly size?: MarkSize;
    readonly marked: boolean;
    readonly markForGroup?: boolean;
    readonly onMark: (marked: boolean, username?: string) => void;
    readonly onGroupMark: (marked: boolean) => void;
    readonly supplyLineId?: string;
    readonly digitalAuctionSupplyType?: DigitalAuctionSupplyType;
    readonly users?: string[];
    readonly comment?: string;
    readonly quantity?: number;
}

export default function MarkButtonMenu(props: MarkButtonMenuProps) {
    const {
        size = MarkSize.Medium,
        marked,
        markForGroup,
        onMark,
        onGroupMark,
        supplyLineId,
        digitalAuctionSupplyType,
        users,
        comment,
        quantity,
    } = props;
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const { classes } = useStyles();
    const { t } = useTranslation(namespaces.supply, { keyPrefix: 'markButton' });

    const queryClient = useQueryClient();
    const { trackMarkedActivity } = useActivityTracker();
    const [userAccount, setUserAccount] = useState<IUser>();
    const [markedForSelfTriggered, setMarkedForSelfTriggered] = useState<boolean>();
    const { isBreakpointSmall } = useBreakpointContext();

    const sortedAccounts = useMemo(getMarkingAccounts, [users, userAccount]);

    function toggleMenu() {
        if (!menuOpen) {
            queryClient.fetchQuery(['supplyLineMarkings', supplyLineId]);
        }
        setMenuOpen(open => !open);
    }

    function getMarkingAccounts() {
        if (!users) {
            return [];
        }

        const allowedAccounts =
            digitalAuctionSupplyType === DigitalAuctionSupplyType.DigitalAuction
                ? users.filter(account => account.includes('@'))
                : users;

        return allowedAccounts.sort((a, b) => a.localeCompare(b));
    }

    useEffect(() => {
        async function getUser() {
            const account = await getUserAccount();
            if (account) {
                setUserAccount(account);
            }
        }

        getUser();
        setMarkedForSelfTriggered(false);
    }, []);

    return (
        <>
            <ButtonGroup
                variant='outlined'
                ref={anchorRef}
                className={`${classes.buttonGroup} ${size == MarkSize.Medium ? classes.medium : ''} ${size == MarkSize.Small ? classes.small : ''}`}
            >
                <MarkButtonContainer
                    onChange={(marked: boolean, username?: string) => {
                        if (markForGroup) {
                            onGroupMark(marked);
                        } else {
                            onMark(marked, username);
                            if (!markedForSelfTriggered) {
                                trackMarkedActivity('for-self');
                                setMarkedForSelfTriggered(true);
                            }
                        }
                        setMenuOpen(false);
                    }}
                    selected={!!marked}
                    comment={comment}
                    quantity={quantity}
                    markForGroup={markForGroup}
                />
                <ToggleIconButton
                    size='small'
                    disabled={!users}
                    onClick={toggleMenu}
                    className={classes.squareIconButton}
                    data-testid='markMenuButton'
                    icon={
                        menuOpen ? (
                            <CloseIcon className={classes.toggleIcons} />
                        ) : (
                            <ArrowDownIcon className={classes.toggleIcons} />
                        )
                    }
                    isSelected={false}
                    color='primary'
                />
            </ButtonGroup>
            {isBreakpointSmall ? (
                <Dialog fullScreen open={menuOpen}>
                    <DialogTitle className={classes.dialogTitle}>
                        {t('markForAnother')}
                        <IconButton
                            className={classes.closeButton}
                            onClick={() => {
                                setMenuOpen(false);
                            }}
                            size='large'
                        >
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <AccountsMarkingList
                            accounts={sortedAccounts}
                            onChange={onMark}
                            supplyLineId={supplyLineId ?? ''}
                        />
                    </DialogContent>
                </Dialog>
            ) : (
                <Popper className={classes.popper} open={menuOpen} anchorEl={anchorRef.current} placement='right-start'>
                    <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                        <Paper elevation={2}>
                            <Box className={classes.paperHeader}>{t('markForAnother')}</Box>
                            <AccountsMarkingList
                                accounts={sortedAccounts}
                                onChange={onMark}
                                supplyLineId={supplyLineId ?? ''}
                            />
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            )}
        </>
    );
}
