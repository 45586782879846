import { ErrorGraphic } from '@floriday/floriday-ui';
import { Box, Button, Container, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import i18n, { namespaces } from '@root/i18n';
import { signOut } from '../auth';

interface IProps {
    readonly error: Error;
}

export default function LoginError(props: IProps) {
    const { error } = props;
    const { t } = useTranslation(namespaces.general);

    const errorMessage = error?.message;
    const readableError = getReadableError();

    function getReadableError() {
        switch (errorMessage) {
            case 'User does not have customer organizations assigned.':
                return t('authorizationError.userHasNoCustomerOrganizations');
            case 'User is not assigned to the client application.':
                return t('authorizationError.unassigned');
            case 'The JWT was issued in the future':
            case 'The JWT expired and is no longer valid':
                return t('authorizationError.deviceTimeError');
            case 'Could not load PKCE codeVerifier from storage. This may indicate the auth flow has already completed or multiple auth flows are executing concurrently.':
                return t('authorizationError.pkceError');
            default:
                return undefined;
        }
    }

    return (
        <Container maxWidth='sm'>
            <Paper>
                <Box mt={6} p={4}>
                    <h2 style={{ marginTop: 0 }} data-testid='authorizationError'>
                        {t('authorizationError.title')}
                    </h2>
                    {readableError ? (
                        <Typography gutterBottom>{readableError}</Typography>
                    ) : (
                        <code>{errorMessage}</code>
                    )}
                    {t('authorizationError.customerService')}
                    <a
                        target='_blank'
                        href={`https://intercom.help/royal-floraholland-buyer-helpcenter/${i18n.language.substring(0, 2)}/articles/9727832`}
                        rel='noreferrer'
                    >
                        {t('generic.rfhHelpcenter')}
                    </a>
                    <Box my={2} textAlign='center'>
                        <ErrorGraphic style={{ maxWidth: 300 }} />
                    </Box>

                    <Button fullWidth variant='contained' color='primary' onClick={signOut}>
                        {t('buttons.signOut')}
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
}
