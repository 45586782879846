import { createContext, useContext, ReactNode, useMemo, useState } from 'react';

import AppsPortalClient from '@api/AppsPortalClient';
import { handleApiGetError } from '@api/helper';
import { usePresetContext } from '@store/Providers/PresetsProvider';

interface ActivityTrackerContextValue {
    trackPageVisitedActivity: (page: string) => Promise<void>;
    trackMarkedActivity: (target: string) => Promise<void>;
    trackPresaleBuyRedirectedActivity: () => Promise<void>;
}

interface IProps {
    readonly children?: ReactNode;
}

const ActivityTrackerContext = createContext<ActivityTrackerContextValue>({
    trackPageVisitedActivity: async () => undefined,
    trackMarkedActivity: async () => undefined,
    trackPresaleBuyRedirectedActivity: async () => undefined,
});

const client = AppsPortalClient.instance.getApiClient();

export function ActivityTrackerProvider(props: IProps) {
    const { children } = props;
    const { selectedPreset } = usePresetContext();
    const [markedTargets, setMarkedTargets] = useState<string[]>([]);
    const [pagesVisited, setPagesVisited] = useState<string[]>([]);

    const value = useMemo<ActivityTrackerContextValue>(
        () => ({
            trackPageVisitedActivity,
            trackMarkedActivity,
            trackPresaleBuyRedirectedActivity,
        }),
        [markedTargets, pagesVisited],
    );

    async function trackPageVisitedActivity(page: string) {
        if (!pagesVisited.includes(page)) {
            const readOnly = selectedPreset?.isReadOnly === true ? 'read-only-' : '';
            const sendActivitySuccessful = await sendActivity(`pre-auction-visited-${readOnly}${page}`);
            if (sendActivitySuccessful) {
                setPagesVisited(prev => [...prev, page]);
            }
        }
    }

    async function trackMarkedActivity(target: string) {
        if (!markedTargets.includes(target)) {
            const sendActivitySuccessful = await sendActivity(`pre-auction-marked-${target}`);
            if (sendActivitySuccessful) {
                setMarkedTargets(prev => [...prev, target]);
            }
        }
    }

    async function trackPresaleBuyRedirectedActivity() {
        await sendActivity(`pre-auction-redirected-buy-pre-sale`);
    }

    async function sendActivity(activity: string) {
        try {
            await client.trackActivityForApp({ event: activity });
            return true;
        } catch (error) {
            handleApiGetError(error, false);
            return false;
        }
    }

    return <ActivityTrackerContext.Provider value={value}>{children}</ActivityTrackerContext.Provider>;
}

export function useActivityTracker(): ActivityTrackerContextValue {
    return useContext(ActivityTrackerContext);
}
