//----------------------
// Created manual based on needed api calls in https://api.staging.floriday.io/apps-portal/api-spec
//----------------------

/* tslint:disable */
/* eslint-disable */
// ReSharper disable InconsistentNaming

export class CustomHeader {
    organizationId = "";
    token = "";
    setOrganizationId(organizationId: string) {
        this.organizationId = organizationId;
    }

    setToken(token: string) {
        this.token = token;
    }

    protected async transformOptions(options: RequestInit): Promise<RequestInit> {
        options.headers = {
            ...options.headers,
            "X-Selected-Organization-Id": this.organizationId,
            Authorization: 'Bearer ' + this.token,
        };
        return options;
    }
}

export class FloridayAppsPortalClient extends CustomHeader {
    private readonly http: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> };
    private readonly baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }) {
        super();
        this.http = http ?? window as any;
        this.baseUrl = baseUrl ?? "";
    }

    /**
     * TrackActivityForApp
     * @param activity
     */
    async trackActivityForApp(activity: Activity): Promise<void> {
        let url_ = this.baseUrl + "/activity-tracker/activity";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(activity);

        let options_: RequestInit = {
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            }
        };

        const transformedOptions_ = await this.transformOptions(options_);
        const _response = await this.http.fetch(url_, transformedOptions_);
        await this.processResponse(_response);
    }

    async getUserAuthorization(): Promise<any> {
        let url_ = this.baseUrl + "/account/users/user-authorization";
        url_ = url_.replace(/[?&]$/, "");

        let options_: RequestInit = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        };

        const transformedOptions_ = await this.transformOptions(options_);
        const _response = await this.http.fetch(url_, transformedOptions_);
        const processedResponse = await this.processResponse(_response);
        return JSON.parse(processedResponse);
    }

    async isPaaAccessOk(): Promise<boolean> {
        let url_ = this.baseUrl + "/account/users/user-authorization";
        url_ = url_.replace(/[?&]$/, "");

        let options_: RequestInit = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        };

        const transformedOptions_ = await this.transformOptions(options_);
        const response = await this.http.fetch(url_, transformedOptions_);
     
        if (response.status == 200 || response.status == 204) {
            return Promise.resolve(true);
        }
        return Promise.resolve(false);
    }
    
    protected async processResponse(response: Response): Promise<string> {
        const status = response.status;
        const _responseText = await response.text();

        if (status === 200) {
            return Promise.resolve(_responseText);
        }
        if (status !== 200 && status !== 204) {
            return throwException("An unexpected server error occurred.", status, _responseText, { });
        }
        const result401 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as ProblemDetails;
        return throwException("A server side error occurred.", status, _responseText, { }, result401);
    }
}

export interface Activity {
    event: string | undefined;
}

export interface ProblemDetails {
    type?: string;
    title?: string;
    status?: number;
    detail?: string;
    instance?: string;

    [key: string]: any;
}

export class SwaggerException extends Error {
    message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isSwaggerException = true;

    static isSwaggerException(obj: any): obj is SwaggerException {
        return obj.isSwaggerException === true;
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    throw new SwaggerException(message, status, response, headers, result);
}