import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

import config from '@config';
import OfflineContainer from '@features/Scan/Components/OfflineContainer/OfflineContainer';
import ScannerComponent from '@features/Scan/Components/ScannerComponent/ScannerComponent';
import { namespaces } from '@root/i18n';
import { useActivityTracker } from '@store/Providers/ActivityTrackerProvider';
import { useFeatureFlags } from '@store/Providers/FeatureFlagsProvider';
import { MarkingQueueProvider } from '@store/Providers/MarkingQueueProvider';
import SDKProvider from '@store/Providers/SdkProvider';

export default function ScanOverview() {
    const { t } = useTranslation(namespaces.general);
    const { hasScanVisible } = useFeatureFlags();
    const { trackEvent } = useIntercom();
    const { trackPageVisitedActivity } = useActivityTracker();

    useEffect(() => {
        if (config.intercom.enabled) {
            trackEvent('RFH Pre-Auction', {
                page: 'scan-overview',
            });
        }
        trackPageVisitedActivity('scan-overview');
    }, []);

    if (!hasScanVisible) {
        return <Box>{t('genericErrors.scanAccessError')}</Box>;
    }

    return (
        <SDKProvider>
            <MarkingQueueProvider>
                <OfflineContainer>
                    <ScannerComponent />
                </OfflineContainer>
            </MarkingQueueProvider>
        </SDKProvider>
    );
}
